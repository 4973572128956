module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"4573a25c-b264-406a-aab1-f0d3dfc54478","server":"https://ackee-2q8g.onrender.com","ignoreLocalhost":true,"ignoreOwnVisits":false,"detailed":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false,"withWebp":true}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null]},
    },{
      plugin: require('/opt/build/repo/gatsby-theme-courses/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/courses"},
    }]
